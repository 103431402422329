import { Amplify } from 'aws-amplify';
import { parseOrgGUID } from '../helpers/Utils';

const bootstrapConfig = async (selectedOrg?: string) => {
  let org = selectedOrg;
  const guid = parseOrgGUID(window.location.pathname);
  if (!org) {
    org = guid.replace('join', '');
  }
  const config = {
    method: 'GET',
    url: `${process.env.REACT_APP_API_GATEWAY_ID}organisation/${org}/loginparameters`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    if (org) {
      const response = await fetch(config.url, config);
      const res = await response.json();
      const oauth =
        res.ssoAuth && res.ssoAuth !== '' && res.ssoAuth.length > 0
          ? {
              domain: `${res.ssoAuth[0].userPoolDomain}.auth.${res.ssoAuth[0].region}.amazoncognito.com`,
              scope: ['email', 'profile', 'openid'],
              redirectSignIn: `${process.env.REACT_APP_BASE_URL}/${org}/hub`,
              redirectSignOut: `${process.env.REACT_APP_BASE_URL}/${org}/logout`,
              responseType: 'code',
              provider: res.ssoAuth[0],
            }
          : null;

      const amplifyConfig = {
        Auth: {
          ...res.auth,
          oauth,
        },
      };

      // Disable Identity Pool
      delete amplifyConfig.Auth.identityPoolId;
      delete amplifyConfig.Auth.oauth?.provider.identityPoolId;

      if (process.env.REACT_APP_AMPLIFY_DEBUG_MODE === 'true') {
        const debugOrgs = process.env.REACT_APP_AMPLIFY_DEBUG_MODE_ORGS;
        const debugOrgsArray = debugOrgs && debugOrgs.length > 0 ? debugOrgs.split(',') : [];

        if (debugOrgsArray.length === 0 || debugOrgsArray.includes(org)) {
          Amplify.Logger.LOG_LEVEL = 'DEBUG';
        }
      }

      Amplify.configure(amplifyConfig);

      return {
        organisationId: org,
        SSO: res.ssoAuth,
        Active: res.active,
      };
    }
    return {};
  } catch (e) {
    return e;
  }
};

export default bootstrapConfig;
